.css-yszrlq-MuiTableCell-root {
  padding: 6px 0px !important;
}

.sidebarSelectContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.sidebarSelectContainer input {
  font-size: 12px;
}

.calenderButton {
  width: 115px;
  height: 27px;
  border-radius: 7px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.summaryCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breakDownButton {
  width: 170px;
  height: 22px;
  border-radius: 0 0 0 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.redirectButton {
  border-radius: 0 0 5px 0;
  padding: 5px 10px;
  cursor: pointer;
}

.sidebarDrawer {
  margin-top: 8dvh;
}

.sidebarDrawer td,
.sidebarDrawer th,
.sidebarDrawer td,
.sidebarDrawer th {
  font-size: 11px;
  padding: 6px 6px;
}

.summaryWrapper{
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: rgba(192, 192, 192, 0.2);
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(87, 87, 87, 0.2)
  }
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 5px;
  padding: 10px;
 
}

.summaryCardBox {
  border-radius: 5px;
  border: 1px solid rgb(196, 196, 196);
}
.summaryCardBoxDetails {
  padding: 5px;
}

.summaryCardHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summaryCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.summaryCardBodyContainer {
  padding: 5px;
}

.summaryCalculationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.summaryCalculationContainerLeft,
.summaryCalculationContainerRight {
  display: flex;
  flex-direction: column;
}

.summaryCalculationContainerLeft {
  align-items: start;
}
.summaryCalculationContainerRight {
  align-items: end;
}

.sidebarButton{
  cursor: pointer;
  width: 30px;
  height: 60px;
  position: absolute;
  background: white;
  border-radius: 0 12px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  transform: 0.5s ease-in-out;
}

.sidebarDetailHeaderLeft {
  display: flex;
  gap: 10px;
  align-items: center;
}

.operationHistoryContainer {
  padding: 0 10px 10px 10px;
}

.pathOfMotioncontianer {
  padding: 10px;
}

.devicesCheckbox {
  display: flex;
  gap: 3px;
  align-items: center;
}

.gisTimelineDrawer {
  padding: 12px;
  background-color: rgba(255, 255, 255,0.8)!important;
}

.gisTimelineHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.timelineTabContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  border-radius: 50px;
  padding: 5px 10px;
  height: 40px;
}

.timelineTabButton {
  border-radius: 40px;
  padding: 7px 15px;
  cursor: pointer;
}

.timelineContainer {
  width: 100%;
  .zoomButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 140px;
  }
}

.timelineCloseButton{
  padding: 10px 15px;
  background: rgb(224, 224, 224);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  outline: none;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.vis-background {
  display: none;
}

.vis-left.vis-panel.vis-vertical-scroll {
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: rgba(192, 192, 192, 0.2);
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(87, 87, 87, 0.2)
  }
}

.timelineContainer .vis-timeline{
  border-radius: 10px;
}
.devicesTimelineContainer .vis-timeline{
  border-radius: 10px;
  overflow: visible!important;
}

.pathOfMotionColor {
  border-radius: 50px;
  width: 10px;
  height: 10px;
  margin-left: 3px;
}

.noDataForCrewIntel{
  margin-top: 3vh;
  text-align: center;
}

@media (max-width: 500px) {
  .sidebarSelectContainer {
    flex-direction: column;
    gap: 10px;
  }
  .calenderButton {
    width: 95%;
  }
}