.plannerBanner {
  margin-top: 8dvh;
  height: 10rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-size: cover;
  background-position: center;
  background-image: url("/public/images/Rectangle.png");
}

.calendarWrapper{
  background-color: #f2ffe6;
  padding: 8vh 0;
}

.calendarContainer {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.pageTitle {
  display : flex;
  justify-content: left; 
  margin-top : 3%; 
  margin-left : 10%; 
  font-size : 25px; 
  font-weight : bold;
  font-family: 'Albert Sans';
}

.custom-event {
  background-color: #a5d6a7; /* Light green card */
  border: 1px solid #388e3c; /* Dark green border */
  border-radius: 8px;
  padding: 5px;
  color: #1b5e20; /* Dark green text */
  font-size: 0.85rem;
  line-height: 1.2;
}

.event-title {
  font-weight: bold;
  margin-bottom: 2px;
}

.event-details {
  font-size: 0.8rem;
}

.event-duration,
.event-type,
.event-task {
  display: block;
}


.fc .fc-button-primary{
  background-color: #62d100;
  border-color: #62d100;
}
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
  background-color: #57b900!important;
  border-color: #57b900!important;
  box-shadow: none!important;

}
.fc .fc-button-active{
  background-color: #57b900!important;
  border-color: #57b900!important;
  box-shadow: none!important;

}
.fc .fc-button-primary:hover{
  background-color: #5dc700;
  border-color: #5dc700;
}
.fc .fc-button-primary:disabled{
  background-color: #5dc700;
  border-color: #5dc700;
}

.fc-button {
  background-color:#62d100;
  border-color: #62d100;
  color: #fff;
}
.fc-button:focus {
  box-shadow: none;
}

.fc-daygrid-event-harness{
  margin: 5px;
}

.fc .fc-daygrid-day-events {
  margin-top: 6px;
}

.fc .fc-daygrid-day-top {
  align-items: center;
  justify-content: center;
}

.fc .fc-toolbar {
  padding: 5px;
}

.fc-toolbar-title {
  color: rgb(66, 66, 66);
  font-family: "Albert Sans"
}

.fc-event-main, .fc-event {
  border: none;
  background: #E0FFC4;
  border-radius: 8px;
  cursor: pointer;
}

.fc-event.fc-selected, .fc-selected {
  background-color: transparent !important;
  border-color: inherit !important; /* Optional: Keeps the border color */
}

.fc-daygrid-event-harness,.fc-daygrid-event-harness::before,.fc-daygrid-day-events, .fc-daygrid-day-events::before, .fc-event-main, .fc-event-main::before, .fc-event::before, .fc-highlight {
  background-color: transparent !important;
  border-color: inherit !important; /* Optional: Keeps the border color */
}

/* Default style for the calendar title */
.fc-toolbar-title {
  font-size: 1.5rem; /* Default size */
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
}

/* Smaller font size for small screens */
@media (max-width: 600px) {
  .fc-toolbar-title {
    font-size: 1rem!important; /* Adjusted size for small screens */
  }
}
